/* Login page css fixed */
.Pages-fullPage-2 {
  align-items: unset !important;
  padding: 90px 0 !important;
}

.Pages-fullPage-2:before {
  background-color: rgba(0, 0, 0, 0) !important;
}

.Pages-fullPage-2:after {
  background-color: rgba(0, 0, 0, 0) !important;
}

.recaptchaContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgetPassword {
  text-align: end;
  margin-bottom: 30px;
}

.switchLanguageButton {
  padding: 10px;
  font-size: 18px;
  color: #1457c4;
}

@media (min-width: 599px) {
  .switchLanguageButton {
    color: #86bacf;
  }
}

.fixedBTColorLogin {
  background-color: #43425d !important;
}

.fixedBTColorReg {
  color: #43425d !important;
}

.mobileLanguageSwitchBT {
  padding: 10px;
  font-size: 18px;
}

.loadingBt {
  margin-right: 20px;
}

.fixedDropdown {
  margin: 0 0 17px 0 !important;
  position: relative !important;
  padding-top: 27px !important;
  vertical-align: unset !important;
}
/* ================ */
/* end of Login css */

/* Modal component css fixed */
.modalBt {
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 0.3125rem 1px;
  padding: 12px 30px;
  position: relative;
  font-size: 12px;
  min-width: auto;
  min-height: auto;
  text-align: center;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  will-change: box-shadow, transform;
  touch-action: manipulation;
  border-radius: 3px;
  letter-spacing: 0;
  text-transform: uppercase;
  vertical-align: middle;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14),
    0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
  background-color: #43425d;
}

.modalBt:hover {
  background-color: #63636a;
}

.modalBtCancel {
  background-color: transparent !important;
  color: #43425d !important;
  transition: none !important;
  box-shadow: none !important;
}

.modalBtCancel:hover {
  color: #63636a !important;
}

.modalBtExit {
  background-color: transparent !important;
  color: #2e86c1 !important;
  transition: none !important;
  box-shadow: none !important;
}

.modalBtExit:hover {
  color: #5dade2 !important;
}

.modalBtDelete {
  background-color: #e74c3c !important;
  color: #fff !important;
  border: none;
  cursor: pointer;
  margin: 0.3125rem 1px;
  padding: 12px 30px;
  position: relative;
  font-size: 12px;
  min-width: auto;
  min-height: auto;
  text-align: center;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  will-change: box-shadow, transform;
  touch-action: manipulation;
  border-radius: 3px;
  letter-spacing: 0;
  text-transform: uppercase;
  vertical-align: middle;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14),
    0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}

.modalBtDelete:hover {
  background-color: #ec7063 !important;
  color: #fff !important;
  border: none;
  cursor: pointer;
  margin: 0.3125rem 1px;
  padding: 12px 30px;
  position: relative;
  font-size: 12px;
  min-width: auto;
  min-height: auto;
  text-align: center;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  will-change: box-shadow, transform;
  touch-action: manipulation;
  border-radius: 3px;
  letter-spacing: 0;
  text-transform: uppercase;
  vertical-align: middle;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14),
    0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}

/* .modalContent {
    top: 40% !important;
} */

.modalContent > h2 {
  font-size: 28px !important;
  font-weight: 500 !important;
}

.GridItem-grid-204 {
  text-align: center;
}

/* Sidebar component css fixed */
.sider_logo_img {
  width: 85%;
}

/* ------------------------------ CSS BY YO --------------------- */
.card-stats {
  margin-top: 15px;
}

.box-chart {
  padding: 30px 0px;
}

.title-header {
  margin-top: 40px;
  padding-bottom: 20px;
  color: darkslategrey;
  font-size: 26px;
}

.text-center-donut {
  position: absolute;
  bottom: 42%;
  left: 45%;
  color: darkslategrey;
  font-size: 16px;
}

.card-title-dashboard {
  color: darkslategrey;
  font-size: 18px;
}

.card-number-dashboard {
  color: #66cc00;
  font-size: 36px;
  font-weight: 900;
  margin-top: 25px;
}

.card-title-dashboard-small {
  color: darkslategrey;
  font-size: 14px;
}

.legend {
}

.pull-right {
  float: right;
}

/* ---------------------- Text Field ---------------- */
.MuiFormControl-root {
  width: 100%;
}

label {
  color: darkslategrey;
  font-size: 16px;
}

.form-group {
  margin-bottom: 0.5rem;
}

.text-button-dialog {
  font-size: 16px;
}

.success {
  background-color: #7fce00;
}

.text-large {
  font-size: 16px !important;
}

.fileinput .thumbnail {
  display: inline-block;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  max-width: 250px;
  max-height: 200px;
}

.fileinput .thumbnail > img {
  width: 250px;
  height: 200px;
}
.form_radio {
  justify-content: flex-start;
  align-items: baseline;
  padding-left: 15px;
}

.form_radio_control {
  justify-content: flex-start;
  align-items: baseline;
}

/* Markkk */
.MuiTableCell-alignRight {
  text-align: left !important;
  flex-direction: row !important;
}
.MuiTableCell-root {
  padding: 14px 4px 14px 16px !important;
}

.MuiTableSortLabel-root {
  width: 140px !important;
}

.MuiTableSortLabel-icon {
  font-size: 20px !important;
}
.MuiInputBase-input {
  /* font-size: 10px !important; */
}
/* Donut charts get built from Pie charts but with a fundamentally difference in the drawing approach. The donut is drawn using arc strokes for maximum freedom in styling */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
