.tab-header {
  font-size: 1.4em;
  
}
.dropdownColumn{
	overflow: inherit !important;

}

.labelFormControl{
	color:black;
	font-size:16;
	font-family: 'Sarabun', sans-serif;
	width: fit-content;
}

.rowInput{
	width: auto !important;
}

h1 h2 h3 h4 h5 h6 {
  font-family: 'Sarabun', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Sarabun&display=swap');
}