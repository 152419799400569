.bgimg {
  background-image: url("../../assets/img/DragDrop.png");
  background-size: cover;
  background-position: center;
}
.showButton {
  color: #0da1c4;
  text-decoration: underline;
  letter-spacing: 0.1em;
}
a {
  color: #0da1c4;
}
a:hover {
  cursor: pointer;
}
.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}
.ignore-css {
  all: unset;
}
i {
  position: absolute;
  top: 38%;
  right: 16%;
}
i:hover {
  color: #00fcb6;
  cursor: pointer;
}
.cardBody {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardContainer {
  height: 100%;
}

.outerCardContainer {
  padding-block: 0px;
  column-gap: 50%;
  width: 100%;
}
.cardBody {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bottomCardContainer {
  display: grid;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  width: 100%;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
}

@media only screen and (max-width: 480px) {
  .bottomCardContainer {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
  }
}
