.showButton {
  text-decoration: underline;

  letter-spacing: 0.1em;
}
a {
  color: #0da1c4;
}
a:hover {
  cursor: pointer;
}

#inner-circle {
  /*  position: absolute;
     background: #FFF;
     border-radius: 50%;
     height:40%;
     width: 40%;
  
     top: 50%;
     left: 50%;
     margin: -22% 0px 0px -60px;
     
      Offset the position correctly with
      minus half of the width and minus half of the height 
     */
}
.cardRightM {
  display: grid;
}
.upperCardContainerM {
  width: 100%;
  margin-bottom: 20px;
}
.bottomCardContainerM {
  display: grid;
  grid-row-gap: 12%;
  grid-column-gap: 12%;
  height: 100%;
  width: 100%;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
}

.statCardBodyM {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cardLeftM {
  display: flex;
  flex-direction: column;
}

.bottomCardStatM {
  height: 100%;
  margin-top: 15px;
}

.bottomCardStatElementM {
  height: 100%;
}

.cardRowM {
  display: flex;
  flex-direction: row;
  column-gap: 10%;
  row-gap: 10%;
}

.card-number-dashboardM {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardBodyM {
  height: 100%;
  display: flex;
  justify-content: space-between;

  flex-direction: column;
}

.cardContainerM {
  height: 100%;
}

.outerCardContainerM {
  padding-block: 0px;
  column-gap: 50%;
  height: 100%;
}

.titleM {
  font-size: 0.8em;
}

/* XL Screen */
@media only screen and (min-width: 1366px) {
  .titleM {
    font-size: 1em;
  }
}

@media only screen and (max-width: 1040px) {
  .bottomCardContainerM {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
  .bottomCardContainerM {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
  }
}
