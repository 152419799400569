.module-button {
  background: #e91e63;
  color: white;
  width: 100px;
  margin: 0 auto;
  border-radius: 80px;
  font-size: 1.2em;
  transition: 300ms;
}
.oldproject-button {
  background: #3b86ff;
  color: white;
  width: 100px;
  margin: 0 auto;
  border-radius: 80px;
  font-size: 1.2em;
  transition: 300ms;
  margin-top: 200px;
}
.module-button:hover {
  background: #3b86ff;
  cursor: pointer;
  transition: 300ms;
  color: #444;
}
