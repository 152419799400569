#inner-circle {
  /*  position: absolute;
   background: #FFF;
   border-radius: 50%;
   height:40%;
   width: 40%;

   top: 50%;
   left: 50%;
   margin: -22% 0px 0px -60px;
   
    Offset the position correctly with
    minus half of the width and minus half of the height 
   */
}
.cardRight {
  display: grid;
}
.upperCardContainer {
  width: 100%;
  margin-bottom: 20px;
}
.bottomCardContainer {
  display: grid;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  width: 100%;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
}

.statCardBody {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cardLeft {
  display: flex;
  flex-direction: column;
}

.bottomCardStat {
  height: 100%;
  margin-top: 15px;
}

.bottomCardStatElement {
  height: 100%;
}

.cardRow {
  display: flex;
  flex-direction: row;
  column-gap: 10%;
  row-gap: 10%;
}

.cardBody {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardContainer {
  height: 100%;
}

.outerCardContainer {
  padding-block: 0px;
  column-gap: 50%;
}

.title {
  font-size: 0.8em;
}

/* XL Screen */
@media only screen and (min-width: 1366px) {
  .title {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 480px) {
  .bottomCardContainer {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
  }
}
