.error {
  color: "red" !important;
}

.right {
  float: right;
}

.Pages-fullPage-2 {
  align-items: unset !important;
  padding: 90px 0 !important;
}

.Pages-fullPage-2:before {
  background-color: rgba(0, 0, 0, 0) !important;
}

.recaptchaContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgetPassword {
  text-align: end;
  margin-bottom: 30px;
}

.switchLanguageButton {
  padding: 10px;
  font-size: 18px;
  color: #1457c4;
}

@media (min-width: 499px) {
  .switchLanguageButton {
    color: #fff !important;
  }
}

.centertext {
  color: #fff !important;
}

.card_loading {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
